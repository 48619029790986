// use 'npm run g:com {name}' - to generate new component with react hooks
import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import cx from 'classnames';
import styles from './DSADetails.module.scss';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { getDSAReduxState, getRsaDsas, IDSAReduxState } from '../../reducers/dsas';
import { SAVE } from '../../actions/dsas';
import { useParams } from 'react-router-dom';
import FormFields from './FormFields';
import { history } from '../../store';
import { Routes } from '../../constants';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const DSADetails = () => {
  const { currentDSAById, isLoading }: IDSAReduxState = useSelector(getDSAReduxState, shallowEqual);
  const { rsaDsa }: IDSAReduxState = useSelector(getRsaDsas, shallowEqual);
  const dispatch = useDispatch();
  const { dsaid } = useParams();
  const [notes, setNotes] = useState('');
  
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'align': [] }],
      ['clean']
    ],
    history: {
      delay: 500,
      userOnly: true
    }
  };
  
  const formats = [
    'header',
    'bold', 'italic', 'underline',
    'list', 'bullet',
    'align',
    'indent'
  ];

  useEffect(() => {
    if (currentDSAById?.notes) {
      setNotes(currentDSAById.notes);
    }
  }, [currentDSAById]);
  

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getRsaDsaValue = (rsaDsa: any) => {
    if (rsaDsa.length > 1) {
      return Object.keys(rsaDsa).map(n => Number(rsaDsa[n].value));
    } else {
      return rsaDsa.value ? [Number(rsaDsa.value)] : [];
    }
  };

  const saveChangesBtnHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const currentEditor = notes;    
    const rsaDsa = e.target['rsaDsa'];
    const rsaDsaValue = getRsaDsaValue(rsaDsa);
    const payload = { id: Number(dsaid), relatedDsaIds: rsaDsaValue, notes: currentEditor };
    const url = Routes.DSADetail.replace(':dsaid', dsaid);
    dispatch({ type: SAVE, payload });
    history.push(url);
  };

  return (
    <div>
      {!isLoading && rsaDsa.length && currentDSAById && (
        <form onSubmit={saveChangesBtnHandler} style={{ width: '100%' }}>
          <Container fluid>
            <Row style={{ justifyContent: 'flex-end' }}>
              <Col className="my-1" xl={1} sm={3} md={2}>
                <Button type="submit" block color="primary">
                  Save
                </Button>
              </Col>
            </Row>
            <hr />
            <FormFields currentDSAById={currentDSAById} rsaDsa={rsaDsa} />
          </Container>
          <div className={cx(styles.notes)}>
          <label className={cx(styles.notesLabel)}>Notes</label>
            <ReactQuill
              value={notes}
              id="notes-editor"
              onChange={setNotes}
              className="notes-editor"
              theme="snow"              
              modules={modules}
              formats={formats}
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default DSADetails;
