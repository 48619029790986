import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Alert } from 'reactstrap';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getApplication, IApplication } from '../../reducers/application';
import { IUser, getUser } from '../../reducers/user';
import { menu } from '../../utils/NavbarData';

import AjaxSpinner from '../../components/AjaxSpinner';
import Notification from '../../containers/Notification';
import { getAuth, IAuthState } from '../../reducers/auth';
import omit from 'lodash/fp/omit';
import { N3AEntitlements } from '../../constants';
import TeamworkNavBar from '../../components/TeamworkNavBar';

export interface ILayoutProps {
  children: React.ReactNode;
}

const agGridLicenseKey = 'Using_this_{AG_Grid}_Enterprise_key_{AG-052653}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Intrado_Corporation}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{JavaScript_frontend_React_library}_only_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{JavaScript_frontend_React_library}_need_to_be_licensed___{JavaScript_frontend_React_library}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{3_March_2025}____[v3]_[01]_MTc0MDk2MDAwMDAwMA==bf256f48a5b21c7e24b47be5a63747ba'

LicenseManager.setLicenseKey(agGridLicenseKey);

const noAccessMessages = {
  notAuthorized: `You are not authorized to access this application`,
  noPermission: `You don't have permissions to access this page`, // TODO: Think about 404 instead
};

const Layout = ({ children }: ILayoutProps) => {
  const app: IApplication = useSelector(getApplication, shallowEqual);
  const user: IUser = useSelector(getUser, shallowEqual);
  const navElements = menu
    .filter(l => user.entitlements.includes(l.access))
    .map(omit(['access']))
    .map(el => {
      return { ...el, route: el.routes[0] };
    });

  const { tokens }: IAuthState = useSelector(getAuth, shallowEqual);

  const [isErrorMessage, setErrState] = useState(true);

  const spinner = app.isLoading && <AjaxSpinner />;

  const errorMessage = app.error && !app.isLoading && (
    <Alert color="danger" isOpen={isErrorMessage} toggle={() => setErrState(false)}>
      Oops!, something went wrong.
    </Alert>
  );

  if (tokens && tokens.idToken) {
    return (
      <div id="TeamworkApp">
        <div id="unison-navbar" style={{ position: 'fixed', top: 0, right: 0, left: 0, zIndex: 1051 }}>
          <TeamworkNavBar navElements={navElements}></TeamworkNavBar>
        </div>
        {spinner}
        {errorMessage}
        <div className="container-fluid page-container">{children}</div>
        <Notification />
      </div>
    );
  }
  return <div />;
};

export const LayoutWrapper = (Page: () => JSX.Element, access = N3AEntitlements.Teamwork) => {
  return function InnerWrapper() {
    const { entitlements: ents } = useSelector(getUser, shallowEqual) || {};
    const { authenticated }: IAuthState = useSelector(getAuth, shallowEqual);
    const isEntitled = (authenticated && ents && ents.length > 0 && ents.includes(access)) || false;

    let noAccessMessage = noAccessMessages.notAuthorized;

    if (authenticated && (!ents || !ents.includes(access))) {
      noAccessMessage = noAccessMessages.noPermission;
    }

    return <Layout>{isEntitled ? <Page /> : <div>{noAccessMessage}</div>}</Layout>;
  };
};
